<template>
  <div>
    <b-overlay :show="loading">
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        v-b-tooltip.hover.v-warning
        title="Descargar Reporte"
        variant="warning"
        class="btn-icon"
        @click="getDataMigraciones(fechaInicio, fechaFin, localidad, etapa, tecnicoAsignado, origenEstado)"
      >
        <feather-icon
          icon="DownloadIcon"
        />
      </b-button>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton, VBPopover, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { calcularFecha, sumarDiasFecha } from '@/utils/fechas'
import { getlistado } from '@/utils/catalogos'
import { getMigraciones } from '@/utils/migracionLuminarias'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'

export default {
  components: {
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    fechaInicio: {
      type: String,
      default: '',
    },
    fechaFin: {
      type: String,
      default: '',
    },
    localidad: {
      type: Object,
      default: () => {},
    },
    etapa: {
      type: Object,
      default: () => {},
    },
    tecnicoAsignado: {
      type: Object,
      default: () => {},
    },
    origenEstado: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      migraciones: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    async getDataMigraciones(fechaInicio, fechaFin, localidad, etapa, tecnicoAsignado, origenEstado) {
      this.loading = true
      try {
        const datofechaInicio = new Date(fechaInicio)
        const datofechaFin = sumarDiasFecha(new Date(fechaFin), 1)
        let filter = {}
        if (this.origenEstado === null) {
          filter = {
            where: {
              and: [
                { companyId: this.usuario.company.id },
                { fechaCreacion: { gte: datofechaInicio.toISOString() } },
                { fechaCreacion: { lte: datofechaFin.toISOString() } },
              ],
            },
            order: ['fechaCreacion DESC'],
          }
          if (etapa && etapa.value !== '') {
            filter.where.and.push({ estado: etapa.value })
          }
        } else if (origenEstado.origen === 'VERIFICADAS') {
          filter = {
            where: {
              and: [
                { companyId: this.usuario.company.id },
                { estado: origenEstado.estado },
                {
                  'seguimiento.tipo': origenEstado.tipo,
                },
                {
                  'seguimiento.fechaHora': { gte: datofechaInicio.toISOString() },
                },
                {
                  'seguimiento.fechaHora': { lte: datofechaFin.toISOString() },
                },
              ],
            },
            order: ['ticket DESC'],
          }
        } else {
          filter = {
            where: {
              and: [
                { companyId: this.usuario.company.id },
                { estado: origenEstado.estado },
                {
                  'tracking.estado': origenEstado.estado,
                },
                {
                  'tracking.horaFecha': { gte: datofechaInicio.toISOString() },
                },
                {
                  'tracking.horaFecha': { lte: datofechaFin.toISOString() },
                },
              ],
            },
            order: ['ticket DESC'],
          }
        }
        if (localidad && localidad.id > 0) {
          filter.where.and.push({ 'levantamientoPunto.localidad.id': localidad.id })
        }
        if (tecnicoAsignado && tecnicoAsignado.id) {
          filter.where.and.push({ 'usuario.id': tecnicoAsignado.id })
        }
        const migraciones = await getMigraciones(filter)
        await this.generarExcel(migraciones)
      } catch (error) {
        console.error(error)
      }
    },
    async generarExcel(migraciones) {
      try {
        // eslint-disable-next-line global-require,no-shadow
        const xl = require('excel4node')
        const wb = new xl.Workbook()
        const ws = wb.addWorksheet('Datos y Detalles Generales')
        const trabajosLevantamiento = await getlistado(30, 'Trabajos Levantamiento', false, this.usuario)

        const styleTitulo = wb.createStyle({
          font: {
            bold: true,
            size: 20,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleTituloSecundario = wb.createStyle({
          font: {
            bold: true,
            size: 13,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleSubtitulo = wb.createStyle({
          font: {
            bold: true,
            size: 10,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleValores = wb.createStyle({
          font: {
            bold: true,
            size: 10,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })
        const styleCeldas = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })

        ws.cell(1, 1, 2, 46, true).string('ALUMBRADO PÚBLICO DE MIXCO, S.A').style(styleTitulo)
        ws.cell(3, 1, 3, 46, true).string('LEVANTAMIENTO INICIAL').style(styleTituloSecundario)
        ws.cell(4, 1, 4, 46, true).string('REPORTE GENERAL DE MIGRACIONES').style(styleSubtitulo)

        ws.cell(6, 1, 6, 12, true).string('Información General').style(styleValores)
        ws.cell(6, 14, 6, 18, true).string('Información de Lámpara Anterior').style(styleValores)
        ws.cell(6, 20, 6, 25, true).string('Información de Lámpara a Instalar').style(styleValores)
        ws.cell(6, 27, 6, 32, true).string('Información de Poste').style(styleValores)
        ws.cell(6, 34, 6, 40, true).string('Información de Brazo').style(styleValores)
        ws.cell(6, 42, 6, 44, true).string('Información de Conexión').style(styleValores)
        ws.cell(6, 46).string('Información de Otros Trabajos').style(styleValores)
        // <!---------Datos Generales ---------------->
        ws.cell(7, 1).string('Número Poste').style(styleValores)
        ws.cell(7, 2).string('Latitud').style(styleValores)
        ws.cell(7, 3).string('Longitud').style(styleValores)
        ws.cell(7, 4).string('Estado').style(styleValores)
        ws.cell(7, 5).string('Asignada a').style(styleValores)
        ws.cell(7, 6).string('Localidad/Población').style(styleValores)
        ws.cell(7, 7).string('Zona').style(styleValores)
        ws.cell(7, 8).string('Fecha de Creación').style(styleValores)
        ws.cell(7, 9).string('Fecha de Migración').style(styleValores)
        ws.cell(7, 10).string('Tipo de Trabajo').style(styleValores)
        ws.cell(7, 11).string('Tipo Instalación').style(styleValores)
        ws.cell(7, 12).string('Usuario Inspecciono').style(styleValores)
        // <!---------Datos de Lámpara Anterior ---------------->
        ws.cell(7, 14).string('Dispositivo').style(styleValores)
        ws.cell(7, 15).string('Clase').style(styleValores)
        ws.cell(7, 16).string('Tipo (Tecnología)').style(styleValores)
        ws.cell(7, 17).string('Potencia').style(styleValores)
        ws.cell(7, 18).string('Serie Lámpara').style(styleValores)
        // <!---------Datos de Lámpara ---------------->
        ws.cell(7, 20).string('Dispositivo').style(styleValores)
        ws.cell(7, 21).string('Clase').style(styleValores)
        ws.cell(7, 22).string('Tipo (Tecnología)').style(styleValores)
        ws.cell(7, 23).string('Potencia').style(styleValores)
        ws.cell(7, 24).string('Ángulo').style(styleValores)
        ws.cell(7, 25).string('Trabajos a Realizar').style(styleValores)
        // <!---------Datos de Poste ---------------->
        ws.cell(7, 27).string('Tipo').style(styleValores)
        ws.cell(7, 28).string('Propiedad').style(styleValores)
        ws.cell(7, 29).string('Estado').style(styleValores)
        ws.cell(7, 30).string('Tamaño').style(styleValores)
        ws.cell(7, 31).string('Trabajos a Realizar').style(styleValores)
        ws.cell(7, 32).string('Poste a Instalar').style(styleValores)
        // <!---------Datos de Brazo ---------------->
        ws.cell(7, 34).string('Tipo').style(styleValores)
        ws.cell(7, 35).string('Herraje Instalado').style(styleValores)
        ws.cell(7, 36).string('Estado').style(styleValores)
        ws.cell(7, 37).string('Tamaño').style(styleValores)
        ws.cell(7, 38).string('Trabajos a Realizar').style(styleValores)
        ws.cell(7, 39).string('Tipo de Brazo a Instalar').style(styleValores)
        ws.cell(7, 40).string('Tipo de Herraje a Instalar').style(styleValores)
        // <!---------Datos de Conexión ---------------->
        ws.cell(7, 42).string('Conectada a').style(styleValores)
        ws.cell(7, 43).string('Trabajos a Realizar').style(styleValores)
        ws.cell(7, 44).string('Metros a Instalar').style(styleValores)
        // <!---------Datos de Otros Trabajos ---------------->
        ws.cell(7, 46).string('Trabajos a Realizar').style(styleValores)

        let numeroFila = 8
        // eslint-disable-next-line no-restricted-syntax
        for await (const migracion of migraciones) {
          const nombresTrabajosLuminaria = this.getTrabajosLuminaria(migracion)
          const nombresTrabajosPoste = this.getTrabajosPoste(migracion)
          const nombresTrabajosBrazo = this.getTrabajosBrazo(migracion)
          const nombresTrabajosConexion = this.getTrabajosConexion(migracion)
          const nombresOtrosTrabajos = this.getOtrosTrabajos(migracion)
          const trackingMigrada = migracion.tracking.find(t => t.estado === 'MIGRADA')
          // <!---------Datos Generales ---------------->
          ws.cell(numeroFila, 1).string(migracion.numeroPoste).style(styleCeldas)
          ws.cell(numeroFila, 2).number(parseFloat(migracion.levantamientoPunto?.puntoInicial?.latitud ?? '')).style(styleCeldas)
          ws.cell(numeroFila, 3).number(parseFloat(migracion.levantamientoPunto?.puntoInicial?.longitud ?? '')).style(styleCeldas)
          ws.cell(numeroFila, 4).string(migracion.estado).style(styleCeldas)
          ws.cell(numeroFila, 5).string(migracion.usuario ? migracion.usuario.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 6).string(migracion.levantamientoPunto.localidad.nombre).style(styleCeldas)
          ws.cell(numeroFila, 7).number(Number(migracion.levantamientoPunto.localidad.zona)).style(styleCeldas)
          ws.cell(numeroFila, 8).string(calcularFecha(migracion.fechaCreacion)).style(styleCeldas)
          ws.cell(numeroFila, 9).string(trackingMigrada ? calcularFecha(trackingMigrada.horaFecha) : '').style(styleCeldas)
          ws.cell(numeroFila, 10).string(migracion.levantamientoPunto.tipoTrabajo.nombre).style(styleCeldas)
          ws.cell(numeroFila, 11).string(migracion.levantamientoPunto && migracion.levantamientoPunto.tipoInstalacion && migracion.levantamientoPunto.tipoInstalacion.nombre ? migracion.levantamientoPunto.tipoInstalacion.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 12).string(migracion.tracking && migracion.tracking[0] && migracion.tracking[0].usuario.nombre).style(styleCeldas)
          // <!---------Datos de Lámpara Anterior---------------->
          ws.cell(numeroFila, 14).string(migracion.levantamientoPunto.puntoInicial && migracion.levantamientoPunto.puntoInicial.dipositivoLampara && migracion.levantamientoPunto.puntoInicial.dipositivoLampara.nombre ? migracion.levantamientoPunto.puntoInicial.dipositivoLampara.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 15).string(migracion.levantamientoPunto.puntoInicial && migracion.levantamientoPunto.puntoInicial.modeloLampara && migracion.levantamientoPunto.puntoInicial.modeloLampara.nombre ? migracion.levantamientoPunto.puntoInicial.modeloLampara.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 16).string(migracion.levantamientoPunto.puntoInicial && migracion.levantamientoPunto.puntoInicial.tecnologiaLampara && migracion.levantamientoPunto.puntoInicial.tecnologiaLampara.nombre ? migracion.levantamientoPunto.puntoInicial.tecnologiaLampara.nombre : '').style(styleCeldas)
          if (migracion.levantamientoPunto && migracion.levantamientoPunto.puntoInicial && typeof migracion.levantamientoPunto.puntoInicial.potencia === 'number') {
            ws.cell(numeroFila, 17).number(migracion.levantamientoPunto.puntoInicial.potencia).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 17).string('').style(styleCeldas)
          }
          ws.cell(numeroFila, 18).string(migracion.levantamientoPunto.puntoInicial && migracion.levantamientoPunto.puntoInicial.serieLampara ? migracion.levantamientoPunto.puntoInicial.serieLampara : '').style(styleCeldas)

          // <!---------Datos de Lámpara ---------------->
          ws.cell(numeroFila, 20).string(migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.dispositivo && migracion.levantamientoPunto.lampara.dispositivo.nombre ? migracion.levantamientoPunto.lampara.dispositivo.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 21).string(migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.clase && migracion.levantamientoPunto.lampara.clase.nombre ? migracion.levantamientoPunto.lampara.clase.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 22).string(migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.tipo && migracion.levantamientoPunto.lampara.tipo.nombre ? migracion.levantamientoPunto.lampara.tipo.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 23).string(migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.lamparaInstalar && migracion.levantamientoPunto.lampara.lamparaInstalar.nombre ? migracion.levantamientoPunto.lampara.lamparaInstalar.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 24).number(migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.anguloInstalacion && migracion.levantamientoPunto.lampara.anguloInstalacion.value ? migracion.levantamientoPunto.lampara.anguloInstalacion.value : 0).style(styleCeldas)
          ws.cell(numeroFila, 25).string(nombresTrabajosLuminaria).style(styleCeldas)

          // <!---------Datos de Poste ---------------->
          ws.cell(numeroFila, 27).string(migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.tipoPoste && migracion.levantamientoPunto.poste.tipoPoste.nombre ? migracion.levantamientoPunto.poste.tipoPoste.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 28).string(migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.propiedad && migracion.levantamientoPunto.poste.propiedad.nombre ? migracion.levantamientoPunto.poste.propiedad.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 29).string(migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.estado && migracion.levantamientoPunto.poste.estado.nombre ? migracion.levantamientoPunto.poste.estado.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 30).string(migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.tamano && migracion.levantamientoPunto.poste.tamano.nombre ? migracion.levantamientoPunto.poste.tamano.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 31).string(nombresTrabajosPoste).style(styleCeldas)
          ws.cell(numeroFila, 32).string(migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.posteInstalar && migracion.levantamientoPunto.poste.posteInstalar.nombre ? migracion.levantamientoPunto.poste.posteInstalar.nombre : '').style(styleCeldas)

          // <!---------Datos de Brazo ---------------->
          ws.cell(numeroFila, 34).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tipo && migracion.levantamientoPunto.brazo.tipo.nombre ? migracion.levantamientoPunto.brazo.tipo.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 35).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tipoHerraje && migracion.levantamientoPunto.brazo.tipoHerraje.nombre ? migracion.levantamientoPunto.brazo.tipoHerraje.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 36).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.estado && migracion.levantamientoPunto.brazo.estado.nombre ? migracion.levantamientoPunto.brazo.estado.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 37).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.tamano && migracion.levantamientoPunto.brazo.tamano.nombre ? migracion.levantamientoPunto.brazo.tamano.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 38).string(nombresTrabajosBrazo).style(styleCeldas)
          // <!---------Brazo a Instalar ---------------->
          ws.cell(numeroFila, 39).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.brazoInstalar && migracion.levantamientoPunto.brazo.brazoInstalar.nombre ? migracion.levantamientoPunto.brazo.brazoInstalar.nombre : '').style(styleCeldas)
          // <!---------Tipo de Herraje ---------------->
          ws.cell(numeroFila, 40).string(migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.herrajeInstalar && migracion.levantamientoPunto.brazo.herrajeInstalar.nombre ? migracion.levantamientoPunto.brazo.herrajeInstalar.nombre : '').style(styleCeldas)
          // <!---------Datos de Conexión ---------------->
          ws.cell(numeroFila, 42).string(migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.tipo && migracion.levantamientoPunto.conexion.tipo.nombre ? migracion.levantamientoPunto.conexion.tipo.nombre : '').style(styleCeldas)
          ws.cell(numeroFila, 43).string(nombresTrabajosConexion).style(styleCeldas)
          ws.cell(numeroFila, 44).number(migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.cantidadTriplex ? migracion.levantamientoPunto.conexion.cantidadTriplex : 0).style(styleCeldas)

          // <!---------Datos de Otros Trabajos a Realizar ---------------->
          ws.cell(numeroFila, 46).string(nombresOtrosTrabajos).style(styleCeldas)

          // eslint-disable-next-line no-plusplus
          numeroFila++
        }

        // <!---------Width de las celdas de la 1 a la 27 ---------------->
        ws.column(1).setWidth(15)
        ws.column(2).setWidth(18)
        ws.column(3).setWidth(18)
        ws.column(4).setWidth(15)
        ws.column(5).setWidth(25)
        ws.column(6).setWidth(25)
        ws.column(7).setWidth(8)
        ws.column(8).setWidth(12)
        ws.column(9).setWidth(12)
        ws.column(10).setWidth(13)
        ws.column(11).setWidth(13)
        ws.column(12).setWidth(15)
        ws.column(13).setWidth(2)
        ws.column(14).setWidth(12)
        ws.column(15).setWidth(12)
        ws.column(16).setWidth(15)
        ws.column(17).setWidth(15)
        ws.column(18).setWidth(15)
        ws.column(19).setWidth(2)
        ws.column(20).setWidth(15)
        ws.column(21).setWidth(15)
        ws.column(22).setWidth(15)
        ws.column(23).setWidth(38)
        ws.column(24).setWidth(10)
        ws.column(25).setWidth(35)
        ws.column(26).setWidth(2)
        ws.column(27).setWidth(15)
        ws.column(28).setWidth(15)
        ws.column(29).setWidth(15)
        ws.column(30).setWidth(15)
        ws.column(31).setWidth(35)
        ws.column(32).setWidth(35)
        ws.column(33).setWidth(2)
        ws.column(34).setWidth(15)
        ws.column(35).setWidth(15)
        ws.column(36).setWidth(15)
        ws.column(37).setWidth(15)
        ws.column(38).setWidth(35)
        ws.column(39).setWidth(35)
        ws.column(40).setWidth(35)
        ws.column(41).setWidth(2)
        ws.column(42).setWidth(15)
        ws.column(43).setWidth(35)
        ws.column(44).setWidth(15)
        ws.column(45).setWidth(2)
        ws.column(46).setWidth(35)

        // Hoja 2
        const ws2 = wb.addWorksheet('Trabajos Seleccionados')
        ws2.cell(1, 1, 2, 17, true).string('ALUMBRADO PUBLICO DE MIXCO, S.A').style(styleTitulo)
        ws2.cell(3, 1, 3, 17, true).string('LEVANTAMIENTO INICIAL').style(styleTituloSecundario)
        ws2.cell(4, 1, 4, 17, true).string('REPORTE GENERAL DE MIGRACIONES').style(styleSubtitulo)

        ws2.cell(6, 1).string('Número Poste').style(styleValores)

        trabajosLevantamiento.forEach((trabajo, index) => {
          ws2.cell(6, 2 + index, true).string('Trabajos Seleccionados').style(styleValores)
          ws2.cell(6, 2 + index).string(trabajo.nombre).style(styleValores)
        })
        const countOKPoste = new Array(trabajosLevantamiento.length).fill(0)
        const countOKBrazo = new Array(trabajosLevantamiento.length).fill(0)
        const countOKLampara = new Array(trabajosLevantamiento.length).fill(0)
        const countOKConexion = new Array(trabajosLevantamiento.length).fill(0)
        const countOKOtros = new Array(trabajosLevantamiento.length).fill(0)
        let numeroRow = 7
        const preciosPorMigracion = []
        // eslint-disable-next-line no-restricted-syntax
        migraciones.forEach(migracion => {
          ws2.cell(numeroRow, 1).string(migracion.numeroPoste).style(styleCeldas)

          let totalPrecioMigracion = 0
          trabajosLevantamiento.forEach((trabajoCatalogo, index) => {
            let isOKPoste = false
            let isOKBrazo = false
            let isOKLampara = false
            let isOKConexion = false
            let isOKOtros = false

            if (migracion.trabajos && migracion.trabajos.poste && migracion.trabajos.poste.trabajos) {
              isOKPoste = migracion.trabajos.poste.trabajos.some(trabajoMigracion => trabajoMigracion.nombre === trabajoCatalogo.nombre)
            }

            if (migracion.trabajos && migracion.trabajos.brazo && migracion.trabajos.brazo.trabajos) {
              isOKBrazo = migracion.trabajos.brazo.trabajos.some(trabajoMigracion => trabajoMigracion.nombre === trabajoCatalogo.nombre)
            }

            if (migracion.trabajos && migracion.trabajos.lampara && migracion.trabajos.lampara.trabajos) {
              isOKLampara = migracion.trabajos.lampara.trabajos.some(trabajoMigracion => trabajoMigracion.nombre === trabajoCatalogo.nombre)
            }

            if (migracion.trabajos && migracion.trabajos.conexion && migracion.trabajos.conexion.trabajos) {
              isOKConexion = migracion.trabajos.conexion.trabajos.some(trabajoMigracion => trabajoMigracion.nombre === trabajoCatalogo.nombre)
            }

            if (migracion.trabajos && migracion.trabajos.otrosTrabajos && migracion.trabajos.otrosTrabajos.trabajos) {
              isOKOtros = migracion.trabajos.otrosTrabajos.trabajos.some(trabajoMigracion => trabajoMigracion.nombre === trabajoCatalogo.nombre)
            }
            // eslint-disable-next-line no-plusplus
            if (isOKPoste) countOKPoste[index]++
            // eslint-disable-next-line no-plusplus
            if (isOKBrazo) countOKBrazo[index]++
            // eslint-disable-next-line no-plusplus
            if (isOKLampara) countOKLampara[index]++
            // eslint-disable-next-line no-plusplus
            if (isOKConexion) countOKConexion[index]++
            // eslint-disable-next-line no-plusplus
            if (isOKOtros) countOKOtros[index]++

            if (isOKPoste || isOKBrazo || isOKLampara || isOKConexion || isOKOtros) {
              ws2.cell(numeroRow, 2 + index).string('✓').style(styleCeldas)
            } else {
              ws2.cell(numeroRow, 2 + index).string('').style(styleCeldas)
            }
          })
          if (migracion.trabajos) {
            Object.values(migracion.trabajos).forEach(tipo => {
              if (tipo.trabajos) {
                tipo.trabajos.forEach(trabajo => {
                  if (trabajo.precio && trabajo.precio.precioVenta) {
                    totalPrecioMigracion += trabajo.precio.precioVenta
                  }
                })
              }
            })
          }

          preciosPorMigracion.push(totalPrecioMigracion)
          ws2.cell(numeroRow, 2 + trabajosLevantamiento.length).number(totalPrecioMigracion).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })

          // eslint-disable-next-line no-plusplus
          numeroRow++
        })
        trabajosLevantamiento.forEach((trabajoCatalogo, index) => {
          const totalPoste = countOKPoste[index]
          const totalBrazo = countOKBrazo[index]
          const totalLampara = countOKLampara[index]
          const totalConexion = countOKConexion[index]
          const totalOtros = countOKOtros[index]

          ws2.cell(numeroRow, 2 + index).number(totalPoste + totalBrazo + totalLampara + totalConexion + totalOtros).style(styleCeldas)
        })
        const totalPrecios = preciosPorMigracion.reduce((acc, precio) => acc + precio, 0)

        ws2.cell(6, 2 + trabajosLevantamiento.length).string('Total').style(styleValores)
        ws2.cell(numeroRow, 2 + trabajosLevantamiento.length).number(totalPrecios).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })

        // <!---------Width de las celdas de la 1 a la 19 ---------------->
        ws2.column(1).setWidth(15)
        ws2.column(2).setWidth(15)
        ws2.column(3).setWidth(20)
        ws2.column(4).setWidth(23)
        ws2.column(5).setWidth(15)
        ws2.column(6).setWidth(15)
        ws2.column(7).setWidth(15)
        ws2.column(8).setWidth(15)
        ws2.column(9).setWidth(15)
        ws2.column(10).setWidth(15)
        ws2.column(11).setWidth(15)
        ws2.column(12).setWidth(15)
        ws2.column(13).setWidth(20)
        ws2.column(14).setWidth(15)
        ws2.column(15).setWidth(25)
        ws2.column(16).setWidth(15)
        ws2.column(17).setWidth(10)
        ws2.column(18).setWidth(10)
        ws2.column(19).setWidth(5)

        const buffer = await wb.writeToBuffer()
        const string = buffer.toString('base64')
        const linkSource = `data:application/xlsx;base64,${string}`
        const downloadLink = document.createElement('a')
        const fileName = 'REPORTE GENERAL DE LEVANTAMIENTO INICIAL.xlsx'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        notificacionInformativa('Documento Excel Descargado', 'Documento Excel Descargado Correctamente')
      } catch (error) {
        console.error(error)
        notificacionError('Inconvenientes!', 'Inconvenientes Descargando Documento Excel!')
      } finally {
        this.loading = false
      }
    },
    getTrabajosLuminaria(migracion) {
      if (migracion && migracion.levantamientoPunto && migracion.levantamientoPunto.lampara && migracion.levantamientoPunto.lampara.trabajos && migracion.levantamientoPunto.lampara.trabajos.length > 0) {
        return migracion.levantamientoPunto.lampara.trabajos.map(trabajo => trabajo.nombre).join(', ')
      }
      return 'NO HAY TRABAJOS A REALIZAR'
    },
    getTrabajosPoste(migracion) {
      if (migracion && migracion.levantamientoPunto && migracion.levantamientoPunto.poste && migracion.levantamientoPunto.poste.trabajos && migracion.levantamientoPunto.poste.trabajos.length > 0) {
        return migracion.levantamientoPunto.poste.trabajos.map(trabajo => trabajo.nombre).join(', ')
      }
      return 'NO HAY TRABAJOS A REALIZAR'
    },
    getTrabajosBrazo(migracion) {
      if (migracion && migracion.levantamientoPunto && migracion.levantamientoPunto.brazo && migracion.levantamientoPunto.brazo.trabajos && migracion.levantamientoPunto.brazo.trabajos.length > 0) {
        return migracion.levantamientoPunto.brazo.trabajos.map(trabajo => trabajo.nombre).join(', ')
      }
      return 'NO HAY TRABAJOS A REALIZAR'
    },
    getTrabajosConexion(migracion) {
      if (migracion && migracion.levantamientoPunto && migracion.levantamientoPunto.conexion && migracion.levantamientoPunto.conexion.trabajos && migracion.levantamientoPunto.conexion.trabajos.length > 0) {
        return migracion.levantamientoPunto.conexion.trabajos.map(trabajo => trabajo.nombre).join(', ')
      }
      return 'NO HAY TRABAJOS A REALIZAR'
    },
    getOtrosTrabajos(migracion) {
      if (migracion && migracion.levantamientoPunto && migracion.levantamientoPunto.otrosTrabajos && migracion.levantamientoPunto.otrosTrabajos.length > 0) {
        return migracion.levantamientoPunto.otrosTrabajos.map(trabajo => trabajo.nombre).join(', ')
      }
      return 'NO HAY TRABAJOS A REALIZAR'
    },
  },
}
</script>
